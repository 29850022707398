import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, X_UNIT_GROUP_ID, SERVER } from 'shared/constants';
import api from './api';

const ACCESS_MANAGEMENT = `${SERVER}/form-data/v1/visitor`;
const ACCESS_MANAGEMENT_API_GETS = ACCESS_MANAGEMENT;
const ACCESS_MANAGEMENT_API_EXPORT = `${ACCESS_MANAGEMENT}/export`;
const ACCESS_MANAGEMENT_API_ADD = ACCESS_MANAGEMENT;
const ACCESS_MANAGEMENT_API_REMOVE = (id) => `${ACCESS_MANAGEMENT}/${id}`;
const ACCESS_MANAGEMENT_API_APPROVE = (id) => `${ACCESS_MANAGEMENT}/${id}/approve`;
const ACCESS_MANAGEMENT_API_CANCEL = (id) => `${ACCESS_MANAGEMENT}/${id}/cancel`;
const ACCESS_MANAGEMENT_API_IN_PROGRESS = (id) => `${ACCESS_MANAGEMENT}/${id}/in-progress`;
const ACCESS_MANAGEMENT_COUNT_BADGES = `${ACCESS_MANAGEMENT}/count-badges`;
const ACCESS_MANAGEMENT_API_COMPLETE = (id) => `${ACCESS_MANAGEMENT}/${id}/complete`;
const ACCESS_MANAGEMENT_COMPLETE_MULTI = `${ACCESS_MANAGEMENT}/complete-multi`;
const ACCESS_MANAGEMENT_APPROVE_MULTI = `${ACCESS_MANAGEMENT}/approve-multi`;
const ACCESS_MANAGEMENT_IN_PROGRESS_MULTI = `${ACCESS_MANAGEMENT}/in-progress-multi`;
const ACCESS_MANAGEMENT_CREATE_MULTI = `${ACCESS_MANAGEMENT}/create-multi`;
const FAMILIAR_CUSTOMER_API_GETS = `${SERVER}/form-data/v1/guest-unit`;
const ACCESS_MANAGEMENT_UPDATE_EMPLOYEE_NOTE = (id) => `${SERVER}/form-data/v1/visitor/${id}`;
const ACCESS_MANAGEMENT_API_GET_HISTORY = (id) => `${SERVER}/activity/list`;

export const getAccessManagementsService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const exportAccessManagementsService = async (filterObj, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_EXPORT,
      method: METHOD.GET,
      options: { params: { ...filterObj }, headers },
    },
  });
  return response;
};

export const addAccessManagementService = async (body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_ADD,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const removeAccessManagementService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_REMOVE(id),
      method: METHOD.DELETE,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const approveAccessManagementService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_APPROVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const rejectAccessManagementService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const inProgressAccessManagementService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_IN_PROGRESS(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const countBadgesAccessManagementService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function completeMultiAccessManagementService({ idBuilding, visitorIds, isAll, filter, images }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_COMPLETE_MULTI,
      method: METHOD.PUT,
      body: {
        visitorIds,
        images,
        isAll,
        filter,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const completeAccessManagementService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_COMPLETE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export async function createMultiAccessManagementService({ idBuilding, listData }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_CREATE_MULTI,
      body: { visitors: listData },
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getListFamiliarCustomerService({ idBuilding, filter, limit, page }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FAMILIAR_CUSTOMER_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const updateEmployeeNoteService = async (body, visitorId, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_UPDATE_EMPLOYEE_NOTE(visitorId),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export async function approveMultiAccessManagementService({ idBuilding, visitorIds, isAll, filter }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_APPROVE_MULTI,
      method: METHOD.PUT,
      body: {
        visitorIds,
        isAll,
        filter,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function inprogressMultiAccessManagementService({ idBuilding, visitorIds, isAll, filter }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_IN_PROGRESS_MULTI,
      method: METHOD.PUT,
      body: {
        visitorIds,
        isAll,
        filter,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getListHistoryService({ idBuilding, visitorId, filter, limit, page }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ACCESS_MANAGEMENT_API_GET_HISTORY(visitorId),
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          visitorId,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
